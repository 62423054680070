var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "img",
        width: "258",
        height: "310",
        viewBox: "-24 -29 350 290",
        preserveAspectRatio: "none",
      },
    },
    [
      _c("rect", {
        staticStyle: { fill: "url('#fill')" },
        attrs: {
          x: "0",
          y: "0",
          width: "100%",
          height: "100%",
          "clip-path": "url(#clip-path)",
        },
      }),
      _c(
        "defs",
        [
          _c("clipPath", { attrs: { id: "clip-path" } }, [
            _c("circle", { attrs: { cx: "16", cy: "27", r: "11" } }),
            _c("rect", {
              attrs: {
                x: "36",
                y: "15",
                rx: "5",
                ry: "5",
                width: "225",
                height: "21",
              },
            }),
            _c("circle", { attrs: { cx: "16", cy: "67", r: "11" } }),
            _c("rect", {
              attrs: {
                x: "36",
                y: "55",
                rx: "5",
                ry: "5",
                width: "225",
                height: "21",
              },
            }),
            _c("circle", { attrs: { cx: "16", cy: "107", r: "11" } }),
            _c("rect", {
              attrs: {
                x: "36",
                y: "95",
                rx: "5",
                ry: "5",
                width: "225",
                height: "21",
              },
            }),
            _c("circle", { attrs: { cx: "16", cy: "147", r: "11" } }),
            _c("rect", {
              attrs: {
                x: "36",
                y: "135",
                rx: "5",
                ry: "5",
                width: "225",
                height: "21",
              },
            }),
            _c("circle", { attrs: { cx: "16", cy: "187", r: "11" } }),
            _c("rect", {
              attrs: {
                x: "36",
                y: "175",
                rx: "5",
                ry: "5",
                width: "225",
                height: "21",
              },
            }),
            _c("circle", { attrs: { cx: "16", cy: "227", r: "11" } }),
            _c("rect", {
              attrs: {
                x: "36",
                y: "215",
                rx: "5",
                ry: "5",
                width: "225",
                height: "21",
              },
            }),
          ]),
          _c(
            "linearGradient",
            { attrs: { id: "fill" } },
            [
              _c(
                "stop",
                {
                  attrs: {
                    offset: "0.599964",
                    "stop-color": "#f3f3f3",
                    "stop-opacity": "1",
                  },
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "offset",
                      values: "-2; -2; 1",
                      keyTimes: "0; 0.25; 1",
                      dur: "2s",
                      repeatCount: "indefinite",
                    },
                  }),
                ]
              ),
              _c(
                "stop",
                {
                  attrs: {
                    offset: "1.59996",
                    "stop-color": "#ecebeb",
                    "stop-opacity": "1",
                  },
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "offset",
                      values: "-1; -1; 2",
                      keyTimes: "0; 0.25; 1",
                      dur: "2s",
                      repeatCount: "indefinite",
                    },
                  }),
                ]
              ),
              _c(
                "stop",
                {
                  attrs: {
                    offset: "2.59996",
                    "stop-color": "#f3f3f3",
                    "stop-opacity": "1",
                  },
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "offset",
                      values: "0; 0; 3",
                      keyTimes: "0; 0.25; 1",
                      dur: "2s",
                      repeatCount: "indefinite",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }